import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1696e290 = () => interopDefault(import('..\\pages\\category\\index.vue' /* webpackChunkName: "pages/category/index" */))
const _0eacc0e0 = () => interopDefault(import('..\\pages\\media.vue' /* webpackChunkName: "pages/media" */))
const _f799c292 = () => interopDefault(import('..\\pages\\product\\index.vue' /* webpackChunkName: "pages/product/index" */))
const _7f4ca0cd = () => interopDefault(import('..\\pages\\setNewPassword.vue' /* webpackChunkName: "pages/setNewPassword" */))
const _758f5f8b = () => interopDefault(import('..\\pages\\category\\searchResult\\index.vue' /* webpackChunkName: "pages/category/searchResult/index" */))
const _2829eed2 = () => interopDefault(import('..\\pages\\home\\api.vue' /* webpackChunkName: "pages/home/api" */))
const _26eac8b4 = () => interopDefault(import('..\\pages\\home\\catalogue.vue' /* webpackChunkName: "pages/home/catalogue" */))
const _30d19420 = () => interopDefault(import('..\\pages\\home\\compareList.vue' /* webpackChunkName: "pages/home/compareList" */))
const _f5a175c6 = () => interopDefault(import('..\\pages\\home\\contact.vue' /* webpackChunkName: "pages/home/contact" */))
const _add9a840 = () => interopDefault(import('..\\pages\\home\\contactPromoCollection.vue' /* webpackChunkName: "pages/home/contactPromoCollection" */))
const _7387c108 = () => interopDefault(import('..\\pages\\home\\currentFlyer\\index.vue' /* webpackChunkName: "pages/home/currentFlyer/index" */))
const _40df5d10 = () => interopDefault(import('..\\pages\\home\\downloadCenter.vue' /* webpackChunkName: "pages/home/downloadCenter" */))
const _21381b50 = () => interopDefault(import('..\\pages\\home\\EDMS.vue' /* webpackChunkName: "pages/home/EDMS" */))
const _7dae7fe0 = () => interopDefault(import('..\\pages\\home\\FAQs.vue' /* webpackChunkName: "pages/home/FAQs" */))
const _983ffe1e = () => interopDefault(import('..\\pages\\home\\loyaltyProgram.vue' /* webpackChunkName: "pages/home/loyaltyProgram" */))
const _42244b12 = () => interopDefault(import('..\\pages\\home\\myDetail\\index.vue' /* webpackChunkName: "pages/home/myDetail/index" */))
const _63d23a6c = () => interopDefault(import('..\\pages\\home\\newsLetter.vue' /* webpackChunkName: "pages/home/newsLetter" */))
const _cb07f9d2 = () => interopDefault(import('..\\pages\\home\\privacyPolicy.vue' /* webpackChunkName: "pages/home/privacyPolicy" */))
const _103a4c4d = () => interopDefault(import('..\\pages\\home\\projects.vue' /* webpackChunkName: "pages/home/projects" */))
const _5b6de330 = () => interopDefault(import('..\\pages\\home\\refundReturns.vue' /* webpackChunkName: "pages/home/refundReturns" */))
const _bd0517c8 = () => interopDefault(import('..\\pages\\home\\termsConditions.vue' /* webpackChunkName: "pages/home/termsConditions" */))
const _4498e538 = () => interopDefault(import('..\\pages\\home\\video.vue' /* webpackChunkName: "pages/home/video" */))
const _b1224798 = () => interopDefault(import('..\\pages\\home\\myDetail\\enquiryDetail.vue' /* webpackChunkName: "pages/home/myDetail/enquiryDetail" */))
const _0b6f5eff = () => interopDefault(import('..\\pages\\home\\myDetail\\orderDetail.vue' /* webpackChunkName: "pages/home/myDetail/orderDetail" */))
const _149f4d88 = () => interopDefault(import('..\\pages\\home\\myDetail\\components\\orderDetailPart.vue' /* webpackChunkName: "pages/home/myDetail/components/orderDetailPart" */))
const _232de836 = () => interopDefault(import('..\\pages\\3D\\_id.vue' /* webpackChunkName: "pages/3D/_id" */))
const _439ac817 = () => interopDefault(import('..\\pages\\article\\_type.vue' /* webpackChunkName: "pages/article/_type" */))
const _45843de1 = () => interopDefault(import('..\\pages\\category\\_firstCategory\\index.vue' /* webpackChunkName: "pages/category/_firstCategory/index" */))
const _08a33590 = () => interopDefault(import('..\\pages\\design\\_id.vue' /* webpackChunkName: "pages/design/_id" */))
const _49bcfbb4 = () => interopDefault(import('..\\pages\\news\\_newsName.vue' /* webpackChunkName: "pages/news/_newsName" */))
const _c37f7f88 = () => interopDefault(import('..\\pages\\pdf\\_id.vue' /* webpackChunkName: "pages/pdf/_id" */))
const _7906b323 = () => interopDefault(import('..\\pages\\product-builder\\_code.vue' /* webpackChunkName: "pages/product-builder/_code" */))
const _422cd5f9 = () => interopDefault(import('..\\pages\\product-record\\_code.vue' /* webpackChunkName: "pages/product-record/_code" */))
const _f404609e = () => interopDefault(import('..\\pages\\product\\_code.vue' /* webpackChunkName: "pages/product/_code" */))
const _16672530 = () => interopDefault(import('..\\pages\\qrcode\\_id.vue' /* webpackChunkName: "pages/qrcode/_id" */))
const _83f98f40 = () => interopDefault(import('..\\pages\\render\\_id.vue' /* webpackChunkName: "pages/render/_id" */))
const _3f297ffc = () => interopDefault(import('..\\pages\\category\\_firstCategory\\_secondCategory\\index.vue' /* webpackChunkName: "pages/category/_firstCategory/_secondCategory/index" */))
const _12e2fb88 = () => interopDefault(import('..\\pages\\orderShare\\_aid\\_id.vue' /* webpackChunkName: "pages/orderShare/_aid/_id" */))
const _75c47988 = () => interopDefault(import('..\\pages\\category\\_firstCategory\\_secondCategory\\_thirdCategory\\index.vue' /* webpackChunkName: "pages/category/_firstCategory/_secondCategory/_thirdCategory/index" */))
const _e5f283d2 = () => interopDefault(import('..\\pages\\orderShare\\_uid\\_aid\\_id.vue' /* webpackChunkName: "pages/orderShare/_uid/_aid/_id" */))
const _5ef652ce = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/category",
    component: _1696e290,
    name: "category"
  }, {
    path: "/media",
    component: _0eacc0e0,
    name: "media"
  }, {
    path: "/product",
    component: _f799c292,
    name: "product"
  }, {
    path: "/setNewPassword",
    component: _7f4ca0cd,
    name: "setNewPassword"
  }, {
    path: "/category/searchResult",
    component: _758f5f8b,
    name: "category-searchResult"
  }, {
    path: "/home/api",
    component: _2829eed2,
    name: "home-api"
  }, {
    path: "/home/catalogue",
    component: _26eac8b4,
    name: "home-catalogue"
  }, {
    path: "/home/compareList",
    component: _30d19420,
    name: "home-compareList"
  }, {
    path: "/home/contact",
    component: _f5a175c6,
    name: "home-contact"
  }, {
    path: "/home/contactPromoCollection",
    component: _add9a840,
    name: "home-contactPromoCollection"
  }, {
    path: "/home/currentFlyer",
    component: _7387c108,
    name: "home-currentFlyer"
  }, {
    path: "/home/downloadCenter",
    component: _40df5d10,
    name: "home-downloadCenter"
  }, {
    path: "/home/EDMS",
    component: _21381b50,
    name: "home-EDMS"
  }, {
    path: "/home/FAQs",
    component: _7dae7fe0,
    name: "home-FAQs"
  }, {
    path: "/home/loyaltyProgram",
    component: _983ffe1e,
    name: "home-loyaltyProgram"
  }, {
    path: "/home/myDetail",
    component: _42244b12,
    name: "home-myDetail"
  }, {
    path: "/home/newsLetter",
    component: _63d23a6c,
    name: "home-newsLetter"
  }, {
    path: "/home/privacyPolicy",
    component: _cb07f9d2,
    name: "home-privacyPolicy"
  }, {
    path: "/home/projects",
    component: _103a4c4d,
    name: "home-projects"
  }, {
    path: "/home/refundReturns",
    component: _5b6de330,
    name: "home-refundReturns"
  }, {
    path: "/home/termsConditions",
    component: _bd0517c8,
    name: "home-termsConditions"
  }, {
    path: "/home/video",
    component: _4498e538,
    name: "home-video"
  }, {
    path: "/home/myDetail/enquiryDetail",
    component: _b1224798,
    name: "home-myDetail-enquiryDetail"
  }, {
    path: "/home/myDetail/orderDetail",
    component: _0b6f5eff,
    name: "home-myDetail-orderDetail"
  }, {
    path: "/home/myDetail/components/orderDetailPart",
    component: _149f4d88,
    name: "home-myDetail-components-orderDetailPart"
  }, {
    path: "/3D/:id?",
    component: _232de836,
    name: "3D-id"
  }, {
    path: "/article/:type?",
    component: _439ac817,
    name: "article-type"
  }, {
    path: "/category/:firstCategory",
    component: _45843de1,
    name: "category-firstCategory"
  }, {
    path: "/design/:id?",
    component: _08a33590,
    name: "design-id"
  }, {
    path: "/news/:newsName?",
    component: _49bcfbb4,
    name: "news-newsName"
  }, {
    path: "/pdf/:id?",
    component: _c37f7f88,
    name: "pdf-id"
  }, {
    path: "/product-builder/:code",
    component: _7906b323,
    name: "product-builder-code"
  }, {
    path: "/product-record/:code",
    component: _422cd5f9,
    name: "product-record-code"
  }, {
    path: "/product/:code",
    component: _f404609e,
    name: "product-code"
  }, {
    path: "/qrcode/:id?",
    component: _16672530,
    name: "qrcode-id"
  }, {
    path: "/render/:id?",
    component: _83f98f40,
    name: "render-id"
  }, {
    path: "/category/:firstCategory/:secondCategory",
    component: _3f297ffc,
    name: "category-firstCategory-secondCategory"
  }, {
    path: "/orderShare/:aid?/:id?",
    component: _12e2fb88,
    name: "orderShare-aid-id"
  }, {
    path: "/category/:firstCategory/:secondCategory/:thirdCategory",
    component: _75c47988,
    name: "category-firstCategory-secondCategory-thirdCategory"
  }, {
    path: "/orderShare/:uid?/:aid?/:id?",
    component: _e5f283d2,
    name: "orderShare-uid-aid-id"
  }, {
    path: "/",
    component: _5ef652ce,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
